<template>
    <div class="main">
        <div class="pb-5">
            <div class="row pb-md-5">
                <div class="col-12 mt-2">
                    <div class="card">
                        <div class="row p-1 pt-0">
                            <div class="col-12 pb-1">
                                <span class="font-weight-bolder">{{t('filters')}}</span>
                            </div>
                            <div class="col-lg-3 col-12 col-md-6">
                                <label>{{t('dateRange')}}</label>
                                <flat-pickr
                                    v-model="range"
                                    class="form-control text-center"
                                    :config="{ mode: 'range'}"
                                    />
                            </div>
                            <div class="col-lg-4 col-12 col-md-6">
                                <label>{{t('status')}}</label>
                                <v-select  v-model="searchStatus"  :options="statusOptions" :get-option-label="(option) => t(option.label)"></v-select>
                            </div>
                            <div class="col-lg-3 col-12 col-md-6">
                                <label>{{t('userId')}}</label>
                                <input v-model="userId" type="text" class="form-control">
                            </div>
                            <div class="col-lg-2 col-12 col-md-6 pt-2 pt-lg-2">
                                <b-button variant="primary" @click="filterTransactions()">
                                    <feather-icon icon="SearchIcon" /> {{t('search')}}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>


                
                <div class="col-12">
                    <div class="card">
                        <b-overlay :show="itemsBusy">
                            <b-table
                            id="users-table"
                            :per-page="perPage"
                            :current-page="currentPage"
                            responsive
                            :items="items"
                            :fields="fields"
                            >
                                <template #head(_id)="scope">
                                    <div class="text-nowrap text-primary">
                                    {{ t(scope.label) }}
                                    </div>
                                </template>

                                <template #head(recipientName)="scope">
                                    <div class="text-nowrap text-primary">
                                    {{ t(scope.label) }}
                                    </div>
                                </template>

                                <template #head()="scope">
                                    <div class="text-nowrap text-primary">
                                    {{ t(scope.column) }}
                                    </div>
                                </template>

                                <template #cell(status)="data">
                                    <b-badge :variant="` ${status[1][data.value]}`" v-if="data.item._id">
                                        {{t(status[0][data.value])}}
                                    </b-badge>
                                </template>

                                <template #cell(amount)="data">
                                    <span class="text-nowrap" v-if="data.item._id">
                                        {{m(data.value)}} <small class="text-primary">{{data.item.currency}}</small>
                                    </span>
                                </template>

                                <template #cell(_id)="data">
                                    <span class="text-nowrap text-dark font-weight-bolder cursor-pointer" v-if="data.item._id" @click="openTransaction(data.value)" v-b-toggle.transaction-sidebar>
                                        {{data.value}}
                                    </span>
                                </template>

                                <template #cell(recipientName)="data">
                                    <span class="text-nowrap" v-if="data.item._id">
                                        {{data.value}}
                                    </span>
                                </template>

                                <template #cell(userId)="data">
                                    <span class="text-nowrap" v-if="data.item._id">
                                        {{data.item.user}}
                                    </span>
                                </template>


                                <template #cell(createdAt)="data">
                                    <span class="text-nowrap" v-if="data.item._id">
                                        {{formatDate(data.value)}}
                                    </span>
                                    <span class="d-block text-nowrap pt-2" v-else>
                                        
                                    </span>
                                </template>
                              

                                

                                <template #cell(name)="data">
                                    <div class="clearfix"  v-if="data.item.firstName">
                                        
                                        <div class="d-flex" align-v="center">
                                            <div>
                                                <b-avatar :src="data.item.avatar? data.item.avatar: ''"  :text="!data.item.avatar? getAvatar(data.item.firstName,data.item.lastName): ''" variant="primary"></b-avatar>
                                            </div>
                                            <div class="w-100 pl-1">
                                                <div :class="font-weight-bolder">
                                                    {{data.item.firstName + ' ' + data.item.lastName }}
                                                </div>
                                                <a :href="`tel:+${data.item.phone}`">
                                                    <small class="text-primary" v-if="data.item.phone">{{data.item.phone}}</small>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template #cell(actions)="data">
                                    <div v-if="data.item._id" class="text-center text-nowrap">
                                        <feather-icon icon="EyeIcon" size="20" class="text-primary cursor-pointer" @click="openTransaction(data.item._id)" v-b-toggle.transaction-sidebar />
                                    </div>

                                </template>

                               
                            </b-table>
                        </b-overlay>
                        <div class="d-flex p-1">
                            <div class="w-100">
                                {{t('page')}} <span class="text-primary"> {{currentPage}}</span> {{t('of')}} <span class="text-primary">{{totalPages}}</span>
                            </div>
                            <div class="w-100">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="users-table"
                                    align="right"
                                    size="sm"
                                    class="my-0"
                                    @page-click="loadNewPage"
                                />
                            </div>
                        </div>
                        <b-sidebar 
                        id="transaction-sidebar"
                        no-header
                        backdrop-variant="dark"
                        backdrop
                        shadow
                        bg-variant="transparent"
                        >
                           <b-card style="height: 100% !important">
                               <div class="text-right">
                                   <feather-icon class="text-primary" icon="XCircleIcon" size="20" v-b-toggle.transaction-sidebar />
                               </div>
                               <h2 class="text-center">
                                   Viewing Transaction
                                </h2>
                                <div>
                                    <b-list-group>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">Transaction ID</small>
                                            <span>{{info._id}}</span>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">amount</small>
                                            <span>{{m(info.amount)}}</span> <small class="text-primary">{{info.currency}}</small>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">Recipient Name</small>
                                            <span>{{info.recipientName}}</span>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">Recipient Account Number</small>
                                            <span>{{info.recipientAccountNumber}}</span>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">Status</small>
                                            <b-badge :variant="` ${status[1][info.status]}`">
                                                {{t(status[0][info.status])}}
                                            </b-badge>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">Payment Method</small>
                                            <span>{{info.paymentMethod}}</span>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">Updated At</small>
                                            <span>{{formatDate(info.updatedAt)}}</span>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <small class="text-primary d-block">Created At</small>
                                            <span>{{formatDate(info.createdAt)}}</span>
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                           </b-card>
                        </b-sidebar>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>

import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import commonApi from '@/services/api';
import Utils from  '@/utils/index';
import Api from './api'

export default {
    name: 'Main',
    components:{
        vSelect,
        flatPickr,
    },
    data() {
        return{
            info: {},
            logo: 'https://erudef.org/wp-content/uploads/2019/10/placeholder-300x200.png',
            itemsBusy: true,
            hasMore: true,
            user: '',
            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            range: '',
            searchDate: '',
            userId: '',
            totalPages: 0,
            searchStatus: '',
            searchParams: {},
            mode: 1,
            status: [{
                1: 'pending', 2: 'done', 3: 'failed'
            },
            {
                1: 'alert-warning', 2: 'alert-success', 3: 'alert-danger'
            }],
            statusOptions: [
                {'label':'pending',"value": 1},
                {'label':'done',"value": 2},
                {'label':'failed',"value": 3},
                {'label':'all', "value": "" },
            ],
            search: {},
            fields: [
                {key: '_id', label: "transactionId"},
                {key: 'recipientName', label: "recipient"},
                {key: 'userId'},
                {key: 'amount'},
                { key: 'status', label: 'status' },
                {key: 'createdAt'},
                {key: 'actions'}
            ],
            items: [],
        }
    },
    computed: {
      rows() {
        return this.totalPages * this.perPage;
      }
    },
    watch: {
        range: function(c) {
            const dates = c.split(' to ');
            if(dates.length) {
                this.searchDate = {};
                this.searchDate.startDate = dates[0];
                this.searchDate.endDate = dates[1]? dates[1] : dates[0];
            }else{
                this.searchDate = '';
            }
        }
    },
    mounted() {
        this.getTransactions();
        if(this.items.length % 10 != 0) {
            const loop = 10 - (this.items.length % 10);
            for(let i = 0; i< loop; i++) {
                this.items.push({});
            }
        }
    },
    methods: {
        openTransaction(id) {
            this.info = this.items.filter(t=> t._id == id)[0];
        },
        log(data) {
            console.log(data);
        },
        formatDate(date) {
            return Utils.formatDate(date);
        },
        getUniqueListBy(arr, key) {
            return [...new Map(arr.map(item => [item[key], item])).values()]
        },
        async getTransactions(search = {},pageNum = 1 ) {
            this.mode = 1;
            this.itemsBusy = true;
            Api.getTransactions({ searchParams: this.searchParams, pageNum: pageNum , pageSize: 10 }).then(res=> {
                if(res.success) {
                    if(pageNum == 1) {
                        this.items = res.data.transactions.list;
                        this.totalPages = res.data.transactions.totalPages;
                        this.hasMore = res.data.transactions.hasMore;
                        this.totalRows = res.data.transactions.total;
                    }else{
                        this.items = this.items.concat(res.data.transactions.list.filter(transaction=> transaction._id ));
                        this.items = this.getUniqueListBy(this.items, '_id');
                    }
                    if(res.data && res.data.transactions && res.data.transactions.list && res.data.transactions.list.length < 10) {
                        let add = 10 - res.data.transactions.list.length;
                        for(let i=0; i<add; i++) {
                            this.items.push({});
                        }
                    }
                    this.totalRows = res.data.transactionstotal;
                    this.itemsBusy = false;
                }
            }).catch(err=>{
                this.itemsBusy = false;
                console.log('Error: ',err);
            })
        },
        loadNewPage(e,page) {
            e.preventDefault();
            this.getTransactions(this.searchParams, page);
            this.currentPage = page;
        },

        m(money, currency = 'XAF', language = 'en-US') {
            return Utils.formatCurrency(money,currency, language)
        },
        filterTransactions() {
            if(!this.searchDate && !this.userId && !this.searchStatus) {
                this.searchParams = {};
            }else{
                this.searchParams = {};
                if(this.userId) {
                    this.searchParams.user = this.userId;
                }
                if(this.searchStatus && this.searchStatus.value) {
                    this.searchParams.status = this.searchStatus.value;
                }
                if(this.searchDate) {
                    this.searchParams.startDate = new Date(this.searchDate.startDate).toISOString();
                    this.searchParams.endDate = new Date(this.searchDate.endDate + ' 23:59:59').toISOString();
                }
            }
            this.getTransactions(this.searchParams, 1);
            this.currentPage = 1;
        },
        showToast(message,  variant = 'danger', title = null) {
            this.$bvToast.toast(`${message}`, {
            title: title || 'ERROR',
            autoHideDelay: 5000,
            appendToast: true,
            solid: true,
            variant: variant
            })
        },
    },
    setup(props) {
    const { t } = useI18nUtils()

    return {
      // i18n
      t,
     }
  }
}
</script>
<style lang="scss" scope="">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .hover-reduce:hover{
        opacity: .7;
    }
    .pt{
        padding-top: 7.5px !important;
    }
    .height-fill{
        height: 80%;
        min-height: 80%;
    }
    .color-light{
        color: #fff !important;
    }
    .txn-logos{
        margin-bottom: 3px;
        width: 40px;
        border-radius: 2px;
    }
    .b-sidebar-body{
        overflow: hidden !important;
    }
</style>