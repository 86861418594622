import request from "@/services/request";
import baseUrl from "@/services/baseUrl";

let servicePath = "/ji036/v1/transaction";

const mpBaseUrl = baseUrl.main + servicePath;
// const address = "http://localhost:6110/v1/transaction/"
// this is for globally used APIs only

export default {
  // getTopicList: (params = {}) => request.post(baseUrl.main + "/as/index/recommend/v1", params)
  getTransactions: (params = {}) => request.post(mpBaseUrl, params),
}